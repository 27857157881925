.hero-background {
  display: flex;
    background-image: url("../../images/circular-yellow.svg"),
      url("../../images/yellow-diamond.svg"),
      url("../../images/circular-yellow.svg");
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: top 131px left 4%, top 365px left 640px, top 620px left 625px;
  
  
  }

  @media only screen and (max-width: 1024px) {
    .hero-background {
      flex-direction: column;
      background-image: url("../../images/circular-yellow.svg"),
      url("../../images/yellow-diamond.svg"),
      url("../../images/circular-yellow.svg");
      background-position: top 20px right 5%, top 230px left 20px, top 310px right 140px;

      margin-bottom: 6rem;
    }
  
  }