@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Lexend:wght@100;200;300;400;500;600;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Poppins:wght@300&family=Space+Grotesk&display=swap');
@import "/node_modules/flag-icons/css/flag-icons.min.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Lexend, Lato, system-ui, sans-serif;
    scroll-behavior: smooth;
  }

  body {
    font-family: "Lexend", sans-serif;
    background-color: #e5e5e5;
  }

  *,
  ::after,
  ::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }
}

@layer components {
  .fi {
    @apply overflow-hidden bg-cover bg-center;
  }
}
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #e6e6e6;
    border-radius: 8px;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: none;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

@layer utilities {
  @variants responsive {
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
}

.page {
  @apply h-screen w-screen;
}

#loading {
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slide-in 0.3s ease-out;
}

@keyframes slide-out {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.slide-out {
  animation: slide-out 0.3s ease-out;
}

.download-link {
  font-size: large;
  font-weight: bold;
}

.dash-line {
  height: 2px;
  background-image: linear-gradient(to right, #E6E6E6 50%, transparent 50%);
  background-size: 1cm 100%;
  width: 100%;
}

.custom-scrollbar {
  scroll-behavior: smooth;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Set the scrollbar width */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #E6E6E6; /* Set the scrollbar thumb color */
  border-radius: 8px; /* Set the thumb border radius */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: none; /* Set the scrollbar track color */
}

@media only screen and (min-width: 2560px) {
  .large-screen {
    width: 60%;
    margin: auto;
  }
}

@media only screen and (max-width: 600px){
  .slick-slide {
    /* padding-left: .2rem;    */
  }
  }
  /* Customize scrollbar color and width */
.privacy::-webkit-scrollbar {
  width: 6px;
}

.privacy::-webkit-scrollbar-thumb {
  background-color: #186F3D;
  border-radius: 8px;
  background-clip: padding-box;
}

.toast-enter {
  @apply animate-slideInRight;
}

.toast-exit {
  @apply animate-slideOutRight;
}

@media only screen and (min-width: 2560px) {
  .large-screen {
    width: 60%;
    margin: auto;
  }
}

@media only screen and (max-width: 600px) {
  .slick-slide {
    /* padding-left: .2rem; */
  }
}