.circle_arrow_btn{
  width: 48px;
  height: 48px;
  border-radius: 100px;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;  
  overflow: hidden;
  position: absolute;
  cursor:pointer;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 #33333329;
}

.left_btn{
    top: 40%;
    right:98%;
    z-index:500;
}

.right_btn{    
    top: 38%;
    left:97%;
}